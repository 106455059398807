window.passwordValidation = function () {
    return {
        hasSpecialCharacter: null,
        hasUppercaseLetter: null,
        hasNumber: null,
        minCharacters: null,
        password: null,
        showPassword: false,
        passwordValidatorIsReady: false,
        checkPassword: function() {
          this.minCharacters = this.checkMinCharacters(this.password);
          this.hasNumber = this.checkForNumber(this.password);
          this.hasUppercaseLetter = this.checkForUppercaseLetter(this.password);
          this.hasSpecialCharacter = this.checkForSpecialCharacter(this.password);

          if (this.minCharacters === false || this.hasNumber === false || this.hasUppercaseLetter === false || this.hasSpecialCharacter === false) {
            this.$refs.passwordInput.setCustomValidity("Invalid password.");
          }
          else {
           this.$refs.passwordInput.setCustomValidity("");
          }
        },
        checkMinCharacters: function(value) {
          if (value == null) {
            return false;
          }
          else if (value.length >= 8) {
            return true;
          }
          else {
            return false;
          }
        },
        checkForNumber: function(value) {
          let hasNumber = /\d/;
          return hasNumber.test(value);
        },
        checkForUppercaseLetter: function(value){
          return /[A-Z]/.test(value);
        },
        checkForSpecialCharacter: function(value){
          const specialChars = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
          return specialChars.test(value);
        }
    };
}
