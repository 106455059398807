import Alpine from 'alpinejs'

import mask from '@alpinejs/mask';
Alpine.plugin(mask);

window.Alpine = Alpine;
Alpine.start();

import Honeybadger from "@honeybadger-io/js";
window.Honeybadger = Honeybadger;

import ExifReader from 'exifreader';
window.ExifReader = ExifReader;

import './components/passwordValidation.js';
import './components/resizeImage.js';
import './components/dataURITo.js';

// https://stackoverflow.com/a/27917744
if (!window.__gCrWeb) window['__gCrWeb'] = {};

var opre;
document.addEventListener("click", function (event) {
  const flyoutEl = document.getElementById("new-message");
  if (opre && flyoutEl) {
    flyoutEl.remove();
  }
  opre = !opre;

});


window.tooltip = function (open, id = "default", val = "") {
  const field = document.getElementById("new-message");
  const container = document.getElementById(id);
  const oldVal = document.getElementById(id + "new-message");
  const width = screen.width;
  const font = width < 450 ? "font-size:x-small;" : "";
  var div;

  switch (id) {
    case "gross-revenue":
      div =
        "The total charges to renters,<div>including all taxes and fees.</div>";
      break;
    case "net-revenue":
      div =
        "Amount deposited into your Stripe Account <div> after Stripe Fees, and RecRe Commission,</div><div> but including taxes.</div>";
      break;
    case "net-income":
      div = "Amount deposited into your Stripe Account,<div>minus taxes.</div>";
      break;
  }
  const newVal = `<div id="new-message" style="max-width:${width}px;"  class="sticky relative z-10  text-gray-950 text-center rounded-md ">
                    <div id="${
                      id + "new-message"
                    }" style="max-width:${width}px;${font}" class="absolute tooltip-left box-content p-4 border-2 bg-gray-200 z-10  text-gray-950 text-center rounded-md">
                    ${div}
                    </div>
                  </div>`;
  if (field && !oldVal) {
    field.remove();
    container.innerHTML += newVal;
  } else if (oldVal) {
    field.remove();
  } else {
    container.innerHTML += newVal;
  }
  opre = !open;
  return !open;
};

import Echo from '@ably/laravel-echo';
import * as Ably from 'ably';

window.Ably = Ably;
window.Echo = new Echo({
    broadcaster: 'ably',
    key: import.meta.env.ABLY_KEY,
});

window.Echo.connector.ably.connection.on(stateChange => {
    console.log("LOGGER:: Connection event :: ", stateChange);

    if (stateChange.current === 'connected') {
        console.log('connected to ably server');
    }
});